import { h, createRef } from 'preact';
import { PureComponent } from 'preact/compat';
import CarouselItem from './CarouselItem/CarouselItem';
import CarouselNavButton from './CarouselNavButton';
import { Wrapper, CarouselWrapper, CarouselInnerWrapper } from './Carousel.emotion';
import { Axis, Transition } from '../../typing/enums';
import { getButtonProps } from '../../common/props';
import { mouseTracker } from '../../utils/mouse';
import { t } from '../../common/text';
import debounce from '../../utils/debounce';

class Carousel extends PureComponent {
  state = {
    index: 0,
    showNext: false,
    showPrev: false
  };

  wrapper = null;

  innerWrapper = null;

  dragging = createRef();

  componentDidMount() {
    this.debouncedGoTo = debounce(this.goToItem, 100);
    this.goToItem(this.getInViewIndex(this.props.index), true);
    this.wrapper.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const isPropChanged = Object.keys(prevProps).some(k => prevProps[k] !== this.props[k]);
    const isStateChanged = Object.keys(prevState).some(k => prevState[k] !== this.state[k]);
    if (isPropChanged) {
      if (this.wrapper) {
        this.goToItem(this.getInViewIndex(this.props.index));
      }
    } else if (isStateChanged) {
      this.goToItem(this.getIndex(this.state.index));
    }

    if (this.props.isZoomed) {
      this.mouseTracker = undefined;
      this.mouseClickTracker = undefined;
    }
  }

  componentWillUnmount() {
    this.wrapper.removeEventListener('scroll', this.handleScroll);
  }

  goToItem = (index, directly) => {
    if (!this.wrapper) return;

    const newIndex =
      index !== undefined
        ? this.getIndex(index)
        : this.getIndex(
            Math.round(
              this.wrapper[this.props.axis === 'horizontal' ? 'scrollLeft' : 'scrollTop'] /
                this.getItemSize()
            )
          );

    this.wrapper.scrollTo({
      [this.props.axis === 'horizontal' ? 'left' : 'top']: newIndex * this.getItemSize(),
      behavior: this.props.transition === Transition.SLIDE && !directly ? 'smooth' : 'instant'
    });

    this.setState({
      index: newIndex,
      showNext: this.isSlidable(newIndex + 1),
      showPrev: this.isSlidable(newIndex - 1)
    });

    this.props.onItemSwipe?.(newIndex);
  };

  nextItem = () => {
    const { index } = this.state;
    if (index < this.props.children.length) {
      this.goToItem(index + 1);
      if (this.props.onNext) {
        this.props.onNext();
      }
    }
  };

  prevItem = () => {
    const { index } = this.state;
    if (index > 0) {
      this.goToItem(index - 1);
      if (this.props.onPrev) {
        this.props.onPrev();
      }
    }
  };

  handleScroll = event => {
    if (event.isTrusted) {
      this.debouncedGoTo();
    }
  };

  dragStart = event => {
    if (!this.props.swipableItem) return;

    this.mouseTracker = mouseTracker(event, this.props.axis);
    this.dragging.current = true;
  };

  dragMove = event => {
    if (!this.dragging.current || !this.props.scrollableGallery) {
      return;
    }

    if (event.type !== 'touchmove') {
      window.requestAnimationFrame(() => this.dragMoveAnimationFrame(event));
    }
  };

  dragMoveAnimationFrame = event => {
    if (!this.mouseTracker) return;

    const { moveByX, moveByY } = this.mouseTracker(event);
    this.wrapper.scrollBy(-1 * moveByX, -1 * moveByY);
  };

  dragEnd = event => {
    if (!this.dragging.current || !this.mouseTracker) return;

    // Handle swipe on non-scrolling galleries - transition: fade/none
    if (!this.props.scrollableGallery) {
      const { moveBy, direction } = this.mouseTracker(event);
      const treshold = this.getItemSize() / 10;
      if (Math.abs(moveBy) > treshold) {
        direction === 'prev' ? this.prevItem() : this.nextItem();
      }
    }

    this.dragging.current = false;
    this.mouseTracker = undefined;
  };

  getWrapper() {
    if (!this.wrapper) throw new Error('Wrapper is undefined');

    return this.wrapper;
  }

  getWrapperSize() {
    return this.props.axis === 'horizontal'
      ? this.getWrapper().clientWidth
      : this.getWrapper().clientHeight;
  }

  isSlidable(nextIndex) {
    return nextIndex >= 0 && nextIndex <= this.getMaxIndex();
  }

  getPerView() {
    const wrapperSize = this.getWrapperSize() + this.props.spacing;
    const itemSize = this.getItemSize();

    return Math.max(Math.floor(wrapperSize / itemSize), 1);
  }

  getMaxIndex() {
    return this.props.children.length - this.getPerView();
  }

  // TODO: check if to add range as a public variable
  getCurrentRange() {
    return {
      startIndex: this.state.index,
      endIndex: this.state.index + this.getPerView() - 1
    };
  }

  isInView(index = 0) {
    const { startIndex = 0, endIndex = 0 } = this.getCurrentRange();

    return index < endIndex && index >= startIndex;
  }

  getIndex(index = 0) {
    if (index >= this.props.children.length || index < 0) {
      return this.state.index;
    }
    const { startIndex = 0 } = this.getCurrentRange();
    const goLeft = index <= startIndex;
    const inViewIndex = this.getInViewIndex(index);

    return goLeft ? index : index <= inViewIndex ? inViewIndex : index;
  }

  getInViewIndex(index = 0) {
    if (this.isInView(index) || index >= this.props.children.length || index < 0) {
      return this.state.index;
    }
    const { startIndex = 0, endIndex = 0 } = this.getCurrentRange();

    return index <= startIndex ? index : index - endIndex + startIndex;
  }

  getItemSize() {
    return this.props.axis === Axis.HORIZONTAL ? this.getItemWidth() : this.getItemHeight();
  }

  getItemWidth() {
    const { itemWidth, axis, spacing } = this.props;

    return itemWidth + (axis === Axis.HORIZONTAL ? spacing : 0);
  }

  getItemHeight() {
    const { itemHeight, axis, spacing } = this.props;

    return itemHeight + (axis === Axis.VERTICAL ? spacing : 0);
  }

  render(props, state) {
    const showNavigation = props.navigation && (state.showPrev || state.showNext);

    const wrapperProps = {
      axis: props.axis,
      itemWidth: props.itemWidth,
      itemHeight: props.itemHeight
    };

    const navProps = {
      ...getButtonProps(this.props, 'navigation'),
      ...wrapperProps,
      float: this.props.navigationFloat,
      gutter: props.navigationGutter
    };

    const carouselProps = {
      ...wrapperProps,
      perView: props.perView,
      spacing: props.spacing
    };

    const itemProps = {
      spacing: props.spacing,
      width: `${props.itemWidth}px`,
      height: `${props.itemHeight}px`,
      transition: props.transition,
      axis: props.axis,
      onItemClick: props.onItemClick
    };

    return (
      <Wrapper className={props.className} data-test="carousel" {...wrapperProps}>
        {showNavigation && (props.navigationFloat ? state.showPrev : true) && (
          <CarouselNavButton
            {...navProps}
            direction="left"
            disabled={!state.showPrev}
            onClick={this.prevItem}
            aria-label={t('previous')}
          />
        )}
        <CarouselWrapper
          data-test="carousel-wrapper"
          innerRef={el => (this.wrapper = el)}
          onTouchStart={this.dragStart}
          onTouchMove={this.dragMove}
          onTouchEnd={this.dragEnd}
          onTouchCancel={this.dragEnd}
          onMouseDown={this.dragStart}
          onMouseMove={this.dragMove}
          onMouseUp={this.dragEnd}
          onMouseLeave={this.dragEnd}
          touch-action="pan-x pan-y pinch-zoom"
          {...carouselProps}
        >
          <CarouselInnerWrapper
            data-test="carousel-inner-wrapper"
            innerRef={el => (this.innerWrapper = el)}
            {...carouselProps}
          >
            {props.children.map((node, i) => {
              return (
                <CarouselItem {...itemProps} key={i} index={i} show={i === props.index}>
                  {node}
                </CarouselItem>
              );
            })}
          </CarouselInnerWrapper>
        </CarouselWrapper>
        {showNavigation && (props.navigationFloat ? state.showNext : true) && (
          <CarouselNavButton
            {...navProps}
            direction="right"
            disabled={!state.showNext}
            onClick={this.nextItem}
            aria-label={t('next')}
          />
        )}
      </Wrapper>
    );
  }
}

export default Carousel;
